<template>
  <div class="evo-legal-wrapper">
    <div class="section-1-half-bg"></div>

    <div class="evo-head text-center">
      <div class="evo-title evo-text-66">
        {{ $t('legal.title') }}
      </div>
      <div class="evo-subtitle evo-text-33 mx-auto">
        {{ $t('legal.desc') }}
      </div>
    </div>

    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: 'EvoMLegalIndexSection'
};
</script>
<style lang="scss" scoped>
.evo-legal-wrapper {
  background: $header_bg_color;
  position: relative;
  padding: 150px 0 64px;
  .evo-head {
    position: relative;
    height: calc(96vw - 150px);
    color: $header_color;
  }
  .section-1-half-bg {
    background-color: $nav_color;
    height: 96vw;
    background-position: left top;
    background-size: 182% !important;
  }
  .evo-title {
    margin-bottom: 30px;
  }
  .evo-subtitle {
    max-width: 610px;
    line-height: 54px;
  }
}
</style>
