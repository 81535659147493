<template>
  <div>
    <template v-if="$vuetify.breakpoint.mobile">
      <EvoMLegalIndexSection />
    </template>
    <template v-else>
      <EvoLegalIndexSection />
    </template>
  </div>
</template>
<script>
import EvoLegalIndexSection from '@/components/legal_sections/index.vue';
import EvoMLegalIndexSection from '@/mobile/components/legal_sections/MIndex.vue';
export default {
  name: 'EvoLegalIndexView',
  components: {
    EvoLegalIndexSection,
    EvoMLegalIndexSection
  }
};
</script>